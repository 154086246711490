const Index = () => import('@/pages/website/Index.vue');
const CompleteRegistration = () => import('@/pages/creators/CompleteRegistration.vue');
const Welcome = () => import('@/pages/website/Welcome.vue');
const Pricing = () => import('@/pages/website/Pricing.vue');
const Subscribe = () => import('@/pages/website/Subscribe.vue');
const Login = () => import('@/pages/website/LoginPage.vue');
const Signup = () => import('@/pages/website/SignupPage.vue');

const routes = [
  {
    path: '/',
    name: 'index',
    components: {
      default: Index,
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    components: {
      default: Welcome,
    },
  },
  {
    path: '/complete-registration',
    name: 'CompleteRegistration',
    component: CompleteRegistration,
  },
  {
    path: '/pricing',
    name: 'pricing',
    components: {
      default: Pricing,
    },
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    components: {
      default: Subscribe,
    },
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: Login,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    components: {
      default: Signup,
    },
  },
];

export default routes;
