import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes/website';
import analytics from '@/services/analytics.service';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(async (to, from) => {
  analytics.page(`/${to.name}`)
});

export default router;
